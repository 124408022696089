import "./styles/App.css";
import Root from "./root";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "./providers/Providers";
import ReactGA from "react-ga4";
import { useEffect } from "react";

// Initialize Google Analytics
ReactGA.initialize("G-LFQ3P6CCSD");

function App() {
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
  }, []);
  return (
    <>
      <BrowserRouter>
        <Provider>
          <>
            <Root />
          </>
        </Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
